import React, { useCallback, useState } from 'react'
import css from './WallpaperTile.module.css'
import BackgroundLoader from '../../UI/BackgroundLoader/BackgroundLoader';
import { useImgLoading } from '../../../hooks/useImgLoading';

export default function WallpaperItem({wallpaperId, imageURL, categories, onClick, ...props}) {
  const [bgUrl, setBgUrl] = useState(null);

  const [loading] = useImgLoading(imageURL, useCallback((img) => {
    setBgUrl(img.src);
  }, []));

  function clickHandler(event) {
    onClick && onClick({
      event, 
      wallpaperId,
      imageURL,
      categories

    });
  }

  return (
    <div className={css.container} onClick={clickHandler} {...props}>
      <BackgroundLoader isVisible={loading}/>
      <div 
        className={css.wallpaperImage} 
        style={{
          backgroundImage: `url("${encodeURI(imageURL)}")`,
          opacity: bgUrl ? 1 : 0
          }}
        >
      </div>
      { categories.length > 0 &&
        <div className={css.tint}></div>
      } 
      <div className={css.bottomZone}>
        {categories.map((category) =>
          <div className={css.category} key={category.id}>
            <span>{category.name}</span>
          </div>
        )}
      </div>
    </div>
  )
}
