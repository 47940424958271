import React from 'react'
import AppBadge from './AppBadge/AppBadge'
import apple from './Apple Badge.png'
import google from './GetItOnGooglePlay_Badge_Web_color_English.png'
import browser from './In browser btn.png'

const badges = {
    appStore: <AppBadge 
        link="https://apps.apple.com/us/app/wallpics-4k-wallpapers/id6446832902?itsct=apps_box_badge&itscg=30200"
        image={apple}
        alt="Download on the App Store"
    />,
    googlePlay: <AppBadge 
        link="https://play.google.com/store/apps/details?id=com.wallpapers.wallpics&pcampaignid=web_share"
        image={google}
        alt="Get it on Google Play"
    />,
    browser: <AppBadge 
        link="/wallpapers"
        image={browser}
        alt="Continue in browser"
        reloadDocument={false}
    />,
}

export default badges;