import React from 'react'
import css from './AppBadge.module.css'
import { Link } from 'react-router-dom'

export default function AppBadge({link, image, alt, reloadDocument = true}) {
  return (
    <Link 
      to={link} 
      className={css.container} 
      reloadDocument={reloadDocument}
      >
      
      <img 
        alt={alt}
        src={image}
        className={css.badgeImg}
        />
    </Link>
  )
}
