import './Home.css';
import iPad from '../../assets/images/iPad.webp';
import iPhone from '../../assets/images/iPhone.webp';
import appIcon from '../../assets/images/appIcon.webp';
import Navbar from '../../components/UI/Navbar/Navbar.jsx';
import badges from '../../components/UI/badges/badges.jsx'

export default function Home() {
	

	return (
	<div className='body'>

		<Navbar variant='transparent'/>

		<section className='continueWith box-container'>
			
			{/* <div className='ratingBadge'>
				<div className="elfsight-app-fd6846f7-0d40-4587-9b6c-097b6230d1ea" data-elfsight-app-lazy></div>
			</div> */}
				
			<div className='appIconBlock'>
				<img src={appIcon} alt='WallPics icon' className='appIcon' />
				<div>
					<h1 className='appName'>
						WallPics - 4K Wallpapers
					</h1>

					<div className="appDownload desktop">
						{badges.appStore}
						{badges.googlePlay}
						{badges.browser}
					</div>

				</div>
			</div>
			
			<div className="appDownload mobile">
				<div className='appDownloadBadge'>
					{badges.appStore}
				</div>
				<div className='appDownloadBadge'>
					{badges.googlePlay}
				</div>
				<div className='appDownloadBadge'>
					{badges.browser}
				</div>
			</div>

		</section>
		<div className='devices'>
			<img 
				src={iPhone} 
				alt="WallPics on iPhone"
				className='deviceImage iPhone'
				/>
			<img 
				src={iPad} 
				alt="WallPics on iPad"
				className='deviceImage iPad'
				/>
		</div>

		<div className='gradientBackground'></div>
		
	</div>
	)
}