import React from 'react'
import css from './Loader.module.css'

export default function Loader() {
  return (
    <div className={css.loaderWrapper}>
        <div className={css.loader}></div>
    </div>
  )
}
