export default class ThemeSwitcher {
    static #themeMode = null;
    static #event = new Event('thememodechange');
    static #colorSchemeMediaQuery = matchMedia('(prefers-color-scheme: dark)');
    
    static #syncTheme = () => {
        const isDarkMode = this.#themeMode === 'system' 
        ? this.#colorSchemeMediaQuery.matches 
        : this.#themeMode === 'dark';
            
        this.#setDarkMode(isDarkMode);
            
        // Set theme color (like in manifest.json but dynamically)
        document.querySelector('meta[name="theme-color"]').setAttribute('content', isDarkMode ? '#141a1a' : '#faffff');
    }

    static #setDarkMode(bool) {
        document.documentElement.setAttribute('dark-mode', bool);
        document.documentElement.classList.toggle('dark', bool);
    }

    static setThemeMode(themeMode) {
        if (this.#themeMode === themeMode) return;

        this.#themeMode = themeMode;
        this.#saveThemeMode(themeMode);

        this.#syncTheme();
        
        // Manage browser theme change event listener
        if (themeMode === 'system') {
            this.#colorSchemeMediaQuery.addEventListener('change', this.#syncTheme);
        } else {
            this.#colorSchemeMediaQuery.removeEventListener('change', this.#syncTheme);
        }

        document.dispatchEvent(this.#event);
    }

    static restoreThemeMode() {
        const themeMode = localStorage.getItem('themeMode');
        const themeModes = {
            system: 'system',
            light: 'light',
            dark: 'dark',
        }
        this.setThemeMode(themeModes[themeMode] || 'system');
    }

    static #saveThemeMode(themeMode) {
        localStorage.setItem('themeMode', themeMode);
    }

    static currentThemeMode() {
        return this.#themeMode;
    }
}
