import React, { useEffect, useRef } from 'react'
import css from './Sidebar.module.css'
import IconBtn from '../IconButton/IconBtn'
import MenuOption from '../MenuOption/MenuOption';
import DarkerBackground from '../DarkerBackground/DarkerBackground';
import { AnimatePresence, motion } from 'framer-motion';

export default function Sidebar({
  isVisible = false,
  onClose = function () { },
  closeOnEscape = true,
  children
}) {
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (!isVisible) return;
    const backgroundFocused = document.activeElement;

    const sidebar = sidebarRef.current;
    sidebar.focus();

    const onKeyDown = (event) => {
      const keyCode = event.code;
      // console.log(keyCode);

      switch (keyCode) {
        case 'Escape':
          if (closeOnEscape) {
            onClose();
            event.stopPropagation();
            backgroundFocused.focus();
          }
          break;

        default:
      }
    }

    sidebar.addEventListener('keydown', onKeyDown);
    return () => sidebar.removeEventListener('keydown', onKeyDown);
  }, [onClose, isVisible, closeOnEscape]);
  return (
    <AnimatePresence>
      {isVisible &&
        <motion.div
          className={css.container + ' blockScroll'}
          tabIndex={0}
          ref={sidebarRef}
        >
          <motion.div
            className={css.contantWrapper}
            initial={{
              x: '100%'
            }}
            animate={{
              x: 0
            }}
            exit={{
              x: '100%'
            }}
            transition={{
              ease: 'easeOut'
            }}
          >
            <div className={css.menuHeader}>
              {/* Sidebar header */}
              <h1>WallPics</h1>

              {/* Close button */}
              <IconBtn
                icon='fa-solid fa-xmark'
                title='Close'
                onClick={() => onClose()}
              />
            </div>
            <div className={css.content}>
              {children}
            </div>
          </motion.div>
          <DarkerBackground
            className={css.background}
            title='Close sidebar'
            onClick={() => onClose()}

          />
        </motion.div>
      }
    </AnimatePresence>
  )
}

export function BuildMenu({ content, defaultOnClick = function () { } }) {
  return content.map((section) =>
    <section className={css.section} key={section.title}>
      {/* Title? */}
      {section.title &&
        <h3 className={css.sectionTitle}>{section.title}</h3>
      }

      {/* Options */}
      <div className={css.sectionOptions}>
        {section.options.map(({ text, icon, ...props }) =>
          <MenuOption
            onClick={() => defaultOnClick()}
            key={text || icon}
            text={text}
            icon={icon}
            {...props}
          />
        )}
      </div>
    </section>
  )
}