import React from 'react'
import css from './RoundButton.module.css'
import classNames from 'classnames/bind'

const cx = classNames.bind(css);

export default function RoundButton({children, icon, position, ...props}) {
  return (
    <button className={cx({
          button: true,
          left: position === 'left',
          right: position === 'right',
          between: position === 'between',
          onlyIcon: !children
      })}
      {...props}
    >
        {icon && <i className={cx(icon, 'buttonIcon')}></i>}
        {children}
    </button>
  )
}
