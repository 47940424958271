import React, { useEffect, useRef } from 'react'
import css from './Modal.module.css'
import DarkerBackground from '../DarkerBackground/DarkerBackground';
import IconBtn from '../IconButton/IconBtn';
import { AnimatePresence, motion } from 'framer-motion';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import classNames from 'classnames/bind';
import { createPortal } from 'react-dom';
const cx = classNames.bind(css);

const mountElement = document.getElementById('overlays');

function Modal({
    isVisible = false, 
    onClose = function () {},
    maxSize = false,
    backgroundClose = true,
    closeOnEscape = true,
    closeButton = true,
    children,
    ...props
  }) {
    const modalRef = useRef(null);
    
    useEffect(() => {
        if (!isVisible) return;
        const backgroundFocused = document.activeElement;
        
        const modal = modalRef.current;
        modal.focus();

        const onKeyDown = (event) => {
            const keyCode = event.code;
            // console.log(keyCode);
            
            switch (keyCode) {
                case 'Escape':
                    if (closeOnEscape) {
                        onClose();
                        event.stopPropagation();
                        backgroundFocused.focus();
                    }
                    break;

                default:
            }
        }

        modal.addEventListener('keydown', onKeyDown);
        return () => modal.removeEventListener('keydown', onKeyDown);
    }, [onClose, isVisible, closeOnEscape]);
    
  return (
    createPortal(
        <AnimatePresence>
            {isVisible &&
                <motion.div 
                    className={cx('container', ' blockScroll', {maxSize})}
                    ref={modalRef}
                    tabIndex={0}
                    {...props}
                    >
                    <SimpleBar style={{maxHeight: '100dvh', width: '100%', height: 'min-content'}}>
                        <div className={cx('modalWrapper', {maxSize})} >
                        
                            <motion.div 
                                className={cx('modal', {maxSize})}
                                initial={{
                                    y: '50vh',
                                    opacity: 0
                                }}
                                animate={{
                                    y: 0,
                                    opacity: 1
                                }}
                                exit={{
                                    y: '50vh',
                                    scale: 0,
                                    opacity: 0
                                }}
                                >
                                {/* Close button */}
                                {closeButton && 
                                    <IconBtn 
                                        className={css.closeButton}
                                        icon='fa-solid fa-xmark'
                                        title='Close'
                                        onClick={() => onClose()}
                                        />
                                }
                                
                                {children}
                            </motion.div>
        
                        </div>
                        <DarkerBackground
                            title='Close'
                            onClick={backgroundClose ? onClose : null} 
                            style={{zIndex: 0, cursor: backgroundClose ? 'pointer' : 'initial'}}
                            
                        />
                    </SimpleBar>
                </motion.div>
            }
        </AnimatePresence>,
        mountElement
    )
  )
}

function ModalContentWrapper({children, ...props}) {
    return (
        <div className={css.contentWrapper} {...props}>
            {children}
        </div>
    )
}

function ModalTitle({children, ...props}) {
    return (
        <h2 className={css.title} {...props}>
            {children}
        </h2>
    )
}

function ModalMessage({children, ...props}) {
    return (
        <span className={css.message}>{children}</span>
    )
}

Modal.ContentWrapper = ModalContentWrapper;
Modal.Title = ModalTitle;
Modal.Message = ModalMessage;

export default Modal;