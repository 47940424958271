import React from 'react'
import css from './PremiumWallpaper.module.css'
import badges from '../../UI/badges/badges'
import BackgroundLoader from '../../UI/BackgroundLoader/BackgroundLoader';
import { useImgLoading } from '../../../hooks/useImgLoading';

export default function PremiumWallpaper({wallpaperId, imageURL, onClick, ...props}) {
    const [loading] = useImgLoading(imageURL);
    
    function clickHandler(event) {
        onClick && onClick({
            event, 
            wallpaperId: wallpaperId,
            imageURL: imageURL,
            premium: true
        });
    }

  return (
    <div className={css.container} onClick={clickHandler} {...props}>
        <div className={css.wallpaperImage} style={{backgroundImage: `url("${encodeURI(imageURL)}")`}}></div>
        <BackgroundLoader isVisible={!loading}/>
        <div className={css.message}>
            <span className={css.messageText}>Install app to view this item</span>
            <div className={css.badges}>
                {badges.appStore}
                {badges.googlePlay}
            </div>
        </div>
    </div>
  )
}
