import React from 'react'
import css from './NotFound404.module.css'
import { Link } from 'react-router-dom'

export default function NotFound404() {
  return (
    <div className="box-container">
      <div className={css.content}>
        <img className={css.image} src="/images/Server-amico.svg" alt="404" />
        <div className='flex flex-col items-center'>
          <h1 className={css.errorCode}>404</h1>
          <h2 className={css.message}>Page not found</h2>
          <Link className={css.link} to="/wallpapers">Go to wallpapers</Link>
        </div>
      </div>
    </div>
  )
}