import { useEffect, useState } from 'react'

export function useImgLoading(imageURL, loadCallback, errorCallback) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    
    useEffect(() => {
        if ( !imageURL ) return;

        setLoading(true);

        const img = new Image();
        img.onload = onLoad;
        img.onerror = onError;

        img.src = encodeURI(imageURL);

        // 

        function onLoad() {
            setLoading(false);
            loadCallback && loadCallback(img);
        }

        function onError(e) {
            setLoading(false);
            setError(e);
            errorCallback && errorCallback(e);
        }

        return () => {
            img.src = '';
            img.onload = null;
            img.onerror = null;
        }
      }, [imageURL, loadCallback, errorCallback]);
    
    return [loading, error];
}
