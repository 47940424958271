import { Link } from 'react-router-dom';
import css from './CategoryBtn.module.css'
import { useEffect, useMemo, useRef } from 'react';


function CategoryBtn({name, emoji, backgroundURL, isActive, url, ...props}) {
	let _name = name;
	let _emoji = emoji;

	if (!emoji) {
		const fullName = name.trim();
		const separated = fullName.split(' ');
		_emoji = separated.pop();
		_name = separated.join(' ');
	}

	const emojis = useMemo(() => {
		if (!_emoji) return;
		return (_emoji + ' ').repeat(64);
	}, [_emoji]);

	const btnRef = useRef(null);

	useEffect(() => {
		if (isActive) {
			// In this case, in Chrome, scrollIntoView works incorrect without delay
			setTimeout(() => {
				btnRef.current.scrollIntoView({inline: 'center', behavior: 'smooth'});
			}, 230); // Works even with 0 delay, 230 just looks better
		}
	}, [isActive]);
	
	return(
		<Link 
			to={isActive
			? '.'
			: url
			}
			className={[
				css.wrapper, 
				isActive ? css.active : ''
			].join(' ')}
			onClick={props.onClick}
			ref={btnRef}
			>
			<div className={css.text}>
				{_name}
			</div>
			
			{!backgroundURL && 
				<div className={css.emojiBackground}>
					<div style={{width: '100%'}}>
						{emojis}
					</div>
				</div>
			}

			{backgroundURL && 
				<div 
					className={css.imageBackground}
					style={{backgroundImage: `url("${encodeURI(backgroundURL)}")`}}
					>

				</div>
			}
		</Link>
	)
}

export default CategoryBtn;