import { useEffect, useState } from "react"

export const useFetching = (fetch, callback) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        let ignore = false;
        
        setIsLoading(true);

        fetch && fetch.then((response) => {
            if (ignore) return;
            callback(response);
        })
        .catch((err) => {
            setError(err.message);
        })
        .finally(() => {
            setIsLoading(false);
        });

        return () => {
            ignore = true;
        }
    }, [fetch, callback]);

    return [isLoading, error];
}