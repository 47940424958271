import React from 'react'

import {
    Route,
    Routes,
    useLocation,
  } from "react-router-dom";
import Home from '../pages/Home/Home.jsx';
import WallpaperCategories from '../pages/WallpaperCategories.jsx';
import Navbar from './UI/Navbar/Navbar.jsx';
import WallpaperOverlay from './WallpaperOverlay/WallpaperOverlay.jsx';
import AliveWallpapers from '../pages/AliveWallpapers.jsx';
import WallpaperPage from '../pages/WallpaperPage/WallpaperPage.jsx';
import NotFound404 from '../pages/NotFound404/NotFound404.jsx';

export default function AppRouter() {
  const location = useLocation();
  const background = location.state?.background;
  
  return (<>
    <Routes>
      {background && <>
        <Route path='wallpaper/:wallpaperId' element={<WallpaperOverlay type='image' />} />
        <Route path='live-wallpaper/:wallpaperId' element={<WallpaperOverlay type='video' />} />
      </>}
    </Routes>

    <Routes location={background?.location || location}>
      <Route index element={<Home />}/>
      <Route element={<Navbar />}>
        <Route path='wallpapers/' element={<WallpaperCategories/>}>
          <Route index element={<AliveWallpapers/>} />
          <Route path='category/:categoryId?' element={<AliveWallpapers/>} />
        </Route>

        <Route path='wallpaper/:wallpaperId' element={<WallpaperPage type='image' />} />
        <Route path='live-wallpaper/:wallpaperId' element={<WallpaperPage type='video' />} />

        
        <Route path='not-found' element={<NotFound404/>} />
        <Route path='*' element={<NotFound404 />} />
      </Route>
      
    </Routes>
  </>)
}
