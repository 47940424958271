import React, { useCallback, useMemo, useState } from 'react'
import Wallpaper from '../../components/Wallpaper/Wallpaper'
import { useNavigate, useParams } from 'react-router-dom';
import { useFetching } from '../../hooks/useFetching';
import wallpaperService from '../../API/WallpaperService';
import liveWallpaperService from '../../API/LiveWallpaperService';

const apiServices = {
    image: wallpaperService,
    video: liveWallpaperService
}

export default function WallpaperPage({type}) {
    const params = useParams();
    const [wallpaperEntry, setWallpaperEntry] = useState(null);
    const navigate = useNavigate();
    
    useFetching(
        useMemo(() => apiServices[type].getById(params.wallpaperId), [params.wallpaperId, type]),
        useCallback((response) => {
            const entry = response.data.data;
            
            if (!entry) navigate('/not-found');
            
            setWallpaperEntry(entry);
        }, [navigate])
    );
  return (
    <div className='box-container'>
        {wallpaperEntry &&
            <Wallpaper wallpaperEntry={wallpaperEntry} type={type} />
        }
    </div>
  )
}
