import React, { useEffect, useRef, useState } from 'react'
import css from './WallpaperView.module.css';
import classNames from 'classnames/bind';
import { useVidLoading } from '../../../hooks/useVidLoading';
import Modal from '../../UI/Modal/Modal';
import RoundButton from '../../UI/RoundButton/RoundButton';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Loader from '../../UI/Loader/Loader';

const cx = classNames.bind(css);

export default function VideoWallpaper({videoURL, preloadImageURL, elementRef, onLoad, onError}) {
    const videoRef = useRef(null);
    const [videoLoaded, setVideoLoaded] = useState(0);

    const [isVidLoading, isVidError] = useVidLoading(videoURL, videoRef, onLoad, onError);

    const [errorMessage, setErrorMessage] = useState(true);

    useEffect(() => {
        setErrorMessage(true);
        setVideoLoaded(0);

        const vid = videoRef.current;

        const onProgress = (event) => {
            const ranges = vid.buffered.length;
            if (!ranges) return;

            const timeTotal = vid.duration;
            const buffered = vid.buffered.end(0);
            const loaded = buffered / timeTotal;

            setVideoLoaded(loaded);
        }

        vid.addEventListener('progress', onProgress);

        return () => {
            vid.removeEventListener('progress', onProgress);
        }
    }, [videoURL]);
    
  return (
    <div>
        {/* Loader */}
        { (isVidLoading) && 
            <div className={`center ${css.vidLoader}`}>
                {videoLoaded > 0
                ?
                    <CircularProgressbar
                        value={videoLoaded} 
                        maxValue={1} 
                        styles={buildStyles({
                            // Colors
                            pathColor: 'var(--main-color)',
                            textColor: 'var(--main-color)',
                            trailColor: 'var(--back-color)',
                            backgroundColor: 'var(--back-color)',
                        })}

                        />
                :
                    <Loader />
                }
            </div>
        }

        {(isVidLoading || isVidError) &&
            <img 
                className={cx('wallpaper', {
                    imageLoading: isVidLoading
                })} 
                src={ preloadImageURL } 
                alt='Wallpaper' 
                
                />
        }

        <video 
            autoPlay={true}
            muted={true}
            ref={(el) => {
                videoRef.current = el;
                if (elementRef) {
                   elementRef.current = el;
                }
            }}
            className={cx('wallpaper', {
                'displayNone': isVidLoading || isVidError
            })}
            alt='Live wallpaper' 
            >
            
            Your browser does not support video tag.
        </video>

        {/* Error message */}
        <Modal
            isVisible={isVidError && errorMessage}
            onClose={() => setErrorMessage(false)}
            closeButton={false}
            backgroundClose={false}
            >
            <Modal.ContentWrapper style={{maxWidth: '400px'}}>
                <Modal.Title>Oops</Modal.Title>
                <Modal.Message>Something went wrong while downloading, or your device does not support the video format.</Modal.Message>
                <RoundButton onClick={() => setErrorMessage(false)}>Ok</RoundButton>
            </Modal.ContentWrapper>
        </Modal>
    </div>
  )
}
