import css from './Wallpaper.module.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import RoundButton from '../UI/RoundButton/RoundButton';
import copyTextToClipboard from '../../utils/copyTextToClipboard';
import downloadMedia from '../../utils/downloadMedia';
import WallpaperFetchList from '../WallpaperFetchList';
import Modal from '../UI/Modal/Modal';
import WallpaperInfo from '../WallpaperInfo';
import IconBtn from '../UI/IconButton/IconBtn';
import ImageWallpaper from './WallpaperView/ImageWallpaper';
import VideoWallpaper from './WallpaperView/VideoWallpaper';
import CategoryBtn from '../UI/CategoryBtn/CategoryBtn';

const onWallpaperItemClick = ({event}) => {
    // Reset scroll
    const simplebarContentWrapper = event.target.closest('.simplebar-content-wrapper');
    simplebarContentWrapper?.scrollTo(0, 0);
}

export default function Wallpaper({wallpaperEntry, type}) {  
    const [infoModal, setInfoModal] = useState(false);
    const [error, setError] = useState(null);

    const wallpaperRef = useRef();

    useEffect(() => {
        setError(null);
    }, [wallpaperEntry])

    const viewTypes = useMemo(() => {return {
        image: (
            <ImageWallpaper 
                imageURL={wallpaperEntry.wallpaper}
                preloadImageURL={wallpaperEntry.thumbnail}
                elementRef={wallpaperRef}

                />
        ),
        video: (
            <VideoWallpaper 
                videoURL={wallpaperEntry.video}
                preloadImageURL={wallpaperEntry.thumbnail}
                elementRef={wallpaperRef}
                onError={() => setError(true)}
                />
        )
    }}, [wallpaperEntry]);

    const filterCurrent = useCallback((entry) => entry.id !== wallpaperEntry.id, [wallpaperEntry.id]);

    const categoryClasses = {
        '12': 'matching',
        '23': 'trio',
      }

    return (<div className={css.container}> 
        <div className='flex flex-col items-center gap-d'>
            <div className='flex flex-col gap-d shrink-0'>
                <div 
                    className={css.imageContainer}

                    >
                    {/* Wallpaper view */}
                    {viewTypes[type]}

                    <div className={css.floatingButtons}>
                        <IconBtn 
                            className={css.floatingButton}
                            icon='fa-solid fa-info'
                            onClick={() => setInfoModal(true)}
                            
                            />
                        {type === 'video' && !error && 
                            <IconBtn 
                                className={css.floatingButton}
                                icon='fa-solid fa-arrow-rotate-left'
                                onClick={() => {
                                    const video = wallpaperRef.current;
                                    video.currentTime = 0;
                                    video.play();
                                }}
                                
                                />
                        }
                    </div>
                </div>

                <div className="flex w-full items-center gap-[3px]">
                    <RoundButton 
                        icon="fa-solid fa-download" 
                        position='left'
                        onClick={() => downloadMedia(type === 'image' ? wallpaperEntry.wallpaper : wallpaperEntry.video, wallpaperEntry.id)}
                        >
                        Download
                    </RoundButton>
                    <RoundButton 
                        icon="fa-solid fa-share-nodes" 
                        position='right' 
                        title='Share' 
                        onClick={() => {
                            if (navigator?.share) {
                                navigator.share({
                                    title: "WallPics - Wallpaper",
                                    text: "4K - Wallpapers",
                                    url: document.location
                                });
                            } else {
                                copyTextToClipboard(document.location)
                                .then(() => alert('The link was copied to your clipboard'))
                                .catch((e) => alert("Something went wrong"));
                            }
                            }}
                        />
                </div>
            </div>
            <div className='w-full flex flex-col items-center place-self-end'>
                {wallpaperEntry.categories.length > 0 && 
                    <div className="flex flex-wrap gap-d mb-d justify-center">
                        {wallpaperEntry.categories.map(category => {
                            const url = category.url || ("/wallpapers/category/" + category.id + '/');
                        
                            return <CategoryBtn 
                                key={category.id}
                                value={category.id} 
                                name={category.name}
                                backgroundURL={category.backgroundULR}
                                url={url}
                                />
                        })}
                    </div>
                }
            
                {wallpaperEntry.tags.length > 0 && 
                    <div className='flex flex-wrap gap-d justify-center'>
                        {wallpaperEntry.tags.map(tag => {
                            return <div key={tag.id} className='border-solid border-2 border-text-muted rounded-d px-2 py-1 text-text-muted'>
                                {tag.name}
                            </div>
                        })}
                    </div>
                }
            </div>
        </div>

        <Modal
            isVisible={infoModal}
            onClose={() => setInfoModal(false)}
            >
            <Modal.ContentWrapper>
                <Modal.Title>Wallpaper info</Modal.Title>
                <WallpaperInfo 
                    wallpaperEntry={wallpaperEntry}
                    imageElement={wallpaperRef.current}
                    type={type}
                />
            </Modal.ContentWrapper>
        </Modal>

        {type === 'image' && <>
            <h2 className='text-center text-2xl my-4 font-normal'>More like this</h2>
            <section className={css.similar}>
                <WallpaperFetchList
                    key={wallpaperEntry.id}
                    fetchBy='category'
                    value={wallpaperEntry.categories[0]?.id}
                    onItemClick={onWallpaperItemClick}
                    // Exclude currently selected wallpaper if category is not "Matching Wallpapers"
                    filter={ !categoryClasses[wallpaperEntry.categories[0]?.id?.toString()] ? filterCurrent : null }

                    />
            </section>
        </>}
    </div>)
}