import { Mime } from 'mime/lite';

import standardTypes from 'mime/types/standard.js';
const mime = new Mime(standardTypes);
mime.define({'video/quicktime': ['mp4']}, true);

export default async function downloadMedia(mediaSrc, mediaName) {
    const file = await fetch(mediaSrc);
    file.crossOrigin = "anonymous";
    const fileBlob = await file.blob();
    const format = mime.getExtension(fileBlob.type);
    const imageURL = URL.createObjectURL(fileBlob);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = mediaName + '.' + format;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}