import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../UI/Modal/Modal';
import IconBtn from '../UI/IconButton/IconBtn';
import css from './WallpaperOverlay.module.css';
import Wallpaper from '../Wallpaper/Wallpaper';
import Loader from '../UI/Loader/Loader';

export default function WallpaperOverlay({children, type}) {
    const location = useLocation();
    const background = location.state.background;

    const {currentEntry} = location.state;
    
    // Set navigation wallpapers
    const [nav, setNav] = useState({});
    useEffect(() => {
        const {list} = background;
    
        const filteredList = list.filter(e => !e.is_premium);
        const idList = filteredList.map(e => e.id);

        const currentIndex = idList.indexOf(currentEntry.id);

        const prev = filteredList[currentIndex - 1];
        const next = filteredList[currentIndex + 1];

        setNav({prev, next});
    }, [background, currentEntry]);
    

    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();

    const close = () => {
        setIsVisible(false);
        setTimeout(() => {
            navigate(background.location);
        }, 200);
    };

    const goTo = useCallback((to) => {
        // Using timeout to remove flickers when navigate in live wallpapers
        setTimeout(() => navigate('../' + to.id, {
            relative: 'path',
            state: {
                currentEntry: to,
                background, 
            }
        }), 16);
    }, [background, navigate]);

    useEffect(() => {
        const onKeyDown = (event) => {
            const keyCode = event.code;
            // console.log(keyCode);
            
            switch (keyCode) {
                case 'ArrowLeft':
                    nav?.prev && goTo(nav.prev);
                    break;
                case 'ArrowRight':
                    nav?.next && goTo(nav.next);
                    break;

                default:
            }
        }

        document.addEventListener('keydown', onKeyDown);

        return () => document.removeEventListener('keydown', onKeyDown);
    }, [goTo, nav]);
    
    useEffect(() => {
        setIsVisible(true);
        return () => setIsVisible(false);
    }, []);

    const arrows = <>
        {nav?.prev && 
            <button 
                onClick={() => goTo(nav.prev)}
                className={css.link + ' ' + css.leftBtn}
                
                >
                
                <IconBtn 
                    icon="fa-solid fa-angle-left" 
                    className={css.navButtons}
                    
                    />
            </button>
        }
        {nav?.next && 
            <button 
                onClick={() => goTo(nav.next)}
                className={css.link + ' ' + css.rightBtn}
                
                >
                
                <IconBtn 
                    icon="fa-solid fa-angle-right" 
                    className={css.navButtons}
                    
                    />
            </button>
        }
    </>
    
  return (<>
    <Modal isVisible={isVisible} onClose={close} maxSize={true}>
        <div className={css.arrows}>{arrows}</div>
        {currentEntry
        ? <Wallpaper 
            type={type} 
            wallpaperEntry={{
                ...currentEntry,
                PO_category_Categories: currentEntry.PO_category_Categories?.id,
            }}/>
        : <Loader isVisible={true}/>
        }
    </Modal>
  </>)
}
