import React from 'react'
import css from './MenuOption.module.css'
import { Link } from 'react-router-dom'

function Block(props) {
    return <div {...{...props, children: undefined}}>{props.children}</div>
}

export default function MenuOption({icon, text, link, onClick, ...props}) {
    const Container = link
    ? Link
    : Block;
    
  return (
    <Container 
        className={css.option} 
        onClick={onClick}
        to={link}
        {...props}
        >
        {icon &&
            <span className={css.icon} >
                <i className={icon} ></i>
            </span>
        }
        <span className={css.text}>
            {text}
        </span>
    </Container>
  )
}
