import React from 'react'
import toFraction from '../utils/toFraction'

export default function WallpaperInfo({wallpaperEntry, imageElement, type}) {
    const width = type === 'image' ? imageElement.naturalWidth : imageElement.videoWidth;
    const height = type === 'image' ? imageElement.naturalHeight : imageElement.videoHeight;

    const time = Date.parse(wallpaperEntry.created_at);
    const date = new Date(time);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateString = date.toLocaleDateString('en-US', options);
  return (
    <div className="flex flex-col gap-d">
        <span className="font-montserrat">
            <i className='fa-solid fa-calendar'></i>
            <span> Uploaded at </span>
            <br />
            <b>{dateString}</b>
        </span>

        <div className='flex flex-row gap-d'>
            <div className={`flex flex-col gap-d rounded-d bg-back-2 p-d`}>
                <h3 className='text-main m-0'>Aspect ratio</h3>
                <span className='font-semibold font-inter'>{width && toFraction(width / height, 0.01).join(':')}</span>
            </div>
            <div className={`flex flex-col gap-d rounded-d bg-back-2 p-d`}>
                <h3 className='text-main m-0'>Resolution</h3>
                <span className='font-semibold font-inter'>{width + 'x' + height}</span>
            </div>
        </div>
    </div>
  )
}
