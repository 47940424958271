import { useEffect, useState } from 'react'

export function useVidLoading(videoURL, videoRef, loadCallback, errCallback) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    
    useEffect(() => {
        if ( !(videoURL && videoRef?.current) ) return;

        setLoading(true);
        setError('');

        var video = videoRef.current;
        video.oncanplaythrough = onLoad;
        video.onerror = onError;
        video.setAttribute("src", encodeURI(videoURL));

        // 

        function onLoad() {
            setLoading(false);
            loadCallback && loadCallback(video);
        }

        function onError(e) {
            setLoading(false);
            setError(e);
            errCallback && errCallback(e);
        }

        return () => {
            video.oncanplaythrough = null;
            video.onerror = null;
            video.setAttribute("src", '');
        }
      }, [videoURL, videoRef, loadCallback, errCallback]);
    
    return [loading, error];
}
