import React, { useContext } from 'react'
import WallpaperFetchList from '../components/WallpaperFetchList'
import KeepAlive from 'react-activation'
import ReactStrictContext from '../context/ReactStrictContext';
import { useOutletContext } from 'react-router-dom';

export default function AliveWallpapers() {
  const reactStrict = useContext(ReactStrictContext);
  const {categoryId} = useOutletContext();

  let fetchBy;
  switch (categoryId) {
    case 'live-wallpapers':
      fetchBy = 'live';
      break;

    case 'new':
      fetchBy = 'new';
      break;

    case undefined:
      fetchBy = 'all';
      break;

    default:
      fetchBy = 'category'
  }
  
  return (
    <div>
      <ConditionalAlive 
        condition={!reactStrict} 
        key={categoryId} 
        saveScrollPosition={false}
        >
        <WallpaperFetchList 
          fetchBy={fetchBy}
          value={categoryId}

          />
      </ConditionalAlive>
    </div>
  )
}
// KeepAlive doesn't work with React strict mode, so
// if strict mode is needed, it disables the react-activation
function ConditionalAlive({condition, children, ...props}) {
  return condition
  ?
    <KeepAlive {...props}>{children}</KeepAlive>
  :
    children
}
