import { useLocation, useNavigate } from "react-router-dom";

function useOverlayNavigation() {
    const location = useLocation();
    const background = location.state?.background;

    const navigate = useNavigate();

    return (url, {list, state}) => {
        navigate(url, {state: {
            ...state,
            background: background || {
                location,
                list,
            }
        }});
    }
}

export default useOverlayNavigation;