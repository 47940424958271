import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import CategoryBtn from '../UI/CategoryBtn/CategoryBtn';
import css from './CategoryList.module.css';
import SimpleBarReact from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';
import classNames from 'classnames/bind';
const cx = classNames.bind(css);

export default function CategoryList({categories}) {
    const location = useLocation();
    const [hide, setHide] = useState(false);
    const cacheScroll = useRef(new Map());
    const {categoryId} = useParams();

    const predefinedCategories = [
        {
            id: 'live_wallpapers',
            name: 'Live Wallpapers 🎞',
            url: '/wallpapers/category/live-wallpapers',
            backgroundULR: 'https://wallup.kyragames.eu/dbase/uploads/EIpF4YtRSusyKAaFHErC_live_gif.gif'
        }
    ];

    useEffect(() => {
        // Infinity to ensures that on location change the category list is visible
        let prevScroll = Infinity;
        
        document.addEventListener('scroll', (event) => { 
            setHide(prevScroll < window.scrollY && window.scrollY > 50);
            prevScroll = window.scrollY;
        })
    }, [location]);

    const allCategories = predefinedCategories.concat(categories);

  return (<>
    {/* Placeholder */}
    <div style={{height: '74px'}}></div>
    
    <SimpleBarReact className={cx({
        backgroundBlur: true,
        scrollContainer: true,
        hide: hide
    })} >
        <div className={css.list}>
            {allCategories.map(entry => {
                const fullName = entry.name.trim();
                const separated = fullName.split(' ');
                const emoji = separated.pop();
                const name = separated.join(' ');

                const url = entry.url || ("/wallpapers/category/" + entry.id + '/');

                const isActive = (location.pathname.includes(url));

                return (
                    <CategoryBtn 
                        key={entry.id}
                        isActive={isActive}
                        value={entry.id} 
                        name={name}
                        emoji={emoji}
                        backgroundURL={entry.backgroundULR}
                        url={url}
                        onClick={(e) => {
                            // Scroll saving and restoring
                            cacheScroll.current.set(categoryId, window.scrollY);
                            window.requestAnimationFrame(() => window.scrollTo(0, cacheScroll.current.get(isActive ? undefined : entry.id)));
                        }}
                        />
                );
            }
            )}
        </div>
    </SimpleBarReact>
  </>)
}
