import React, { useCallback, useMemo, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useFetching } from '../hooks/useFetching';
import categoryService from '../API/CategoryService';
import CategoryList from '../components/CategoryList/CategoryList';
import { AliveScope } from 'react-activation';

export default function WallpaperCategories() {
  const {categoryId} = useParams();

  const [categories, setCategories] = useState([]);
  useFetching(
    useMemo(() => categoryService.get(1, 10000), []),
    useCallback((response) => {
      setCategories(response.data.data);
    }, [])
  );

  return (<>
    <CategoryList categories={categories}/>
    <div 
      className='box-container' 
      style={{
        paddingBottom: 'var(--default-gap)', 
      }}
      >
      
      <AliveScope>
        <Outlet context={{categoryId}}/>
      </AliveScope>
    </div>
    
    <div className='keepScrollY'></div>
  </>)
}
