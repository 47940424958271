import React, { useEffect, useRef, useState } from 'react'
import css from './Navbar.module.css'
import { Link, Outlet } from 'react-router-dom'
import ThemeSwitcher from '../../../utils/ThemeSwitcher';
import IconBtn from '../IconButton/IconBtn';
import { AliveScope } from 'react-activation';
import Sidebar, { BuildMenu } from '../Sidebar/Sidebar';
import classNames from 'classnames/bind';

const cx = classNames.bind(css);

const MIN_SCROLL_VALUE_PX = 2;

ThemeSwitcher.restoreThemeMode();
const themeControlButtonVariants = {
    system: { 
        text: 'System theme', 
        icon: 'fa-solid fa-circle-half-stroke', 
        onClick: () => ThemeSwitcher.setThemeMode('dark')
    },

    dark: {
        text: 'Dark theme', 
        icon: 'fa-solid fa-moon', 
        onClick: () => ThemeSwitcher.setThemeMode('light')
    },

    light: { 
        text: 'Light theme', 
        icon: 'fa-solid fa-sun', 
        onClick: () => ThemeSwitcher.setThemeMode('system')
    },
};

export default function Navbar({variant = 'regular'}) {
    const [scrolled, setScrolled] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [themeMode, setThemeMode] = useState(ThemeSwitcher.currentThemeMode());
    const headerRef = useRef(null);

    function handleScroll() {
        setScrolled(window.scrollY > MIN_SCROLL_VALUE_PX)
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const borderBoxSize = entries[0].borderBoxSize[0];
            document.body.style.setProperty("--header-height", borderBoxSize.blockSize + 'px');
        })

        resizeObserver.observe(headerRef.current);
        
        function onThemeModeChange() {
            setThemeMode(ThemeSwitcher.currentThemeMode());
        }

        document.addEventListener('thememodechange', onThemeModeChange);
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('thememodechange', onThemeModeChange);
            document.removeEventListener('scroll', handleScroll);
        }
    }, [])
  return (
    <>
        <div className={css.headerPlaceholder}></div>

        <header 
            ref={headerRef}
            className= {cx('header', {
                regular: variant === 'regular',
                backgroundBlur: variant === 'regular',
                transparent: variant === 'transparent',
                scrolled: scrolled,
            })}
            >
            
            <div className={css.navbar + ' py-dh px-d'}>
                <Link to="/wallpapers">
                    <h1 className={css.logo}>Wallpics</h1>
                </Link>

                <div>
                    <IconBtn 
                        icon='fa-solid fa-bars' 
                        title="Menu"
                        onClick={() => setSidebar( true )}
                        />
                </div>

            </div>
        </header>

        <Sidebar 
            isVisible={sidebar}
            onClose={() => setSidebar( false )}
            >
            <BuildMenu 
                content={[
                    {
                        title: 'Content',
                        options: [
                            { 
                                text: 'Start page', 
                                icon: 'fa-solid fa-house', 
                                link: '/'
                            },
                            { 
                                text: 'Wallpapers', 
                                icon: 'fa-solid fa-images', 
                                link: '/wallpapers'
                            },
                            
                        ]
                    },
                    {
                        title: 'Settings',
                        options: [
                            themeControlButtonVariants[themeMode],
                        ]
                    },
                    {
                        title: 'About',
                        options: [
                            {
                                text: 'Privacy Policy',
                                icon: 'fa-solid fa-user-lock',
                                link: '/privacy.html',
                                reloadDocument: true
                            }
                        ]
                    }
                ]}
                />
        </Sidebar>

        <AliveScope>
            <Outlet />
        </AliveScope>
    </>
  )
}
