import React from 'react'
import css from './IconBtn.module.css'

export default function IconBtn({icon, className, ...props}) {
  return (
    <span 
      className={css.button + ' ' + className} 
      {...props}
      >
      <i className={icon}></i>
    </span>
  )
}
