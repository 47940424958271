import AbstractService from "./AbstractService";

class WallpaperService extends AbstractService {
    
    byCategory(categoryId) {
        if ( categoryId ) {
            this.setPathParams([categoryId]); 
        }

        return this;
    }   
}

const wallpaperService = new WallpaperService({
    apiEndpoint: '/api/wallpaper-list',
    singleEntryApiEndpoint: '/api/wallpaper',
});
export default wallpaperService;