import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import './utils/adjustScrollBlockingPadding';
import ReactStrictContext from './context/ReactStrictContext';


// Should be disabled for react-activation library
const REACT_STRICT_MODE = true;

const enableReactStrictMode = process.env.NODE_ENV === 'development'
    ? REACT_STRICT_MODE
    : false;

function ConditionalStrictMode({condition, children}) {
  return ( condition
  ?
    <React.StrictMode>
      {children}
    </React.StrictMode>
  :
    <>{children}</>
  )
}
    
function App() {

  return (
    <div className="App">
      <ReactStrictContext.Provider value={enableReactStrictMode}>
        <ConditionalStrictMode condition={enableReactStrictMode}>
          <BrowserRouter>
            <Routes>
              <Route 
                path="*" 
                element={<AppRouter/>} 
                  
                />
            </Routes>
          </BrowserRouter>
        </ConditionalStrictMode>
      </ReactStrictContext.Provider>
    </div>
  );
}

export default App;
