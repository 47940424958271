import axios from 'axios';

const DEFAULT_LIMIT = 25;
const DEFAULT_DATA = {
    paginated: '1',
    page: '1',
};

export default class AbstractService {

    // Init data with default values
    #data = {...DEFAULT_DATA};

    #headers = {
        'x-auth': '1724923087',
        'x-token': '5e2c254ddef88ff0f7f2e4ab6290c707',
    };
    
    constructor({
        baseURL = process.env.REACT_APP_API_URL,
        apiEndpoint = '',
        singleEntryApiEndpoint = '',
    }) {
        this.apiEndpoint = apiEndpoint;
        this.pathParams = [];
        this.singleEntryApiEndpoint = singleEntryApiEndpoint;

        this.api = axios.create({
            baseURL: baseURL,
        });
    }
    
    setData = (data) => {
        Object.assign(this.#data, data);
    }

    setPathParams = (arr) => {
        this.pathParams = arr;
    }

    resetData = () => {
        this.#data = {...DEFAULT_DATA};
        this.pathParams = [];
    }

    select = () => {}

    sortBy = (columnName) => {
        if ( columnName ) {
            this.setData({columnName});
        }
        return this;
    }

    orderBy = (order) => {
        if ( order ) {
            this.setData({orderBy: order});
        }

        return this;
    }

    get = async (page, limit = DEFAULT_LIMIT) => {
        this.setData({page, limit});

        this.select();
        
        const response = await this.api.get(this.apiEndpoint + (this.pathParams.length > 0 ? '/' + this.pathParams.join('/') : ''), { 
            params: this.#data,
            headers: { ...this.#headers}
        });

        this.resetData();

        return response;
    }

    getById = async (id) => {
        this.select();

        this.setPathParams([id]);
        
        const response = await this.api.get(this.singleEntryApiEndpoint + '/' + this.pathParams.join('/'), {
            headers: { ...this.#headers}
        });

        this.resetData();

        return response;
    }
}