import AbstractService from "./AbstractService";

class LiveWallpaperService extends AbstractService {
    select = () => {
        this.setPathParams([0, 0, 1])
    } 
}

const liveWallpaperService = new LiveWallpaperService({
    apiEndpoint: '/api/wallpaper-list',
    singleEntryApiEndpoint: '/api/wallpaper',
});
export default liveWallpaperService;