import React from 'react'
import WallpaperItem from './wallpaper_tiles/WallpaperTile/WallpaperTile'
import PremiumWallpaper from './wallpaper_tiles/PremiumWallpaper/PremiumWallpaper'
import useOverlayNavigation from '../hooks/useOverlayNavigation';

export default function WallpaperList({list, navigateTo, onItemClick, onPremiumItemClick, className = '', ...props}) {
  const overlayNavigate = useOverlayNavigation();

  function clickHandler(args) {
    overlayNavigate( navigateTo + args.wallpaperId, {
      list,
      state: {
        currentEntry: list.find(entry => entry.id === args.wallpaperId),
      }
    });
    
    onItemClick && onItemClick({...args, list});
  }

  function premiumClickHandler(args) {
    onPremiumItemClick && onPremiumItemClick({...args, list});
  }
  
  return (
    <div className={[className, "wallpaper-list"].join(' ')} {...props}>
      {list.map((wallpaper) =>  
        wallpaper.is_premium
        ?
          <PremiumWallpaper
            key={wallpaper.id}
            wallpaperId={wallpaper.id}
            imageURL={wallpaper.thumbnail}
            onClick={premiumClickHandler}
            />
        :
          <WallpaperItem 
            key={wallpaper.id}
            wallpaperId={wallpaper.id}
            imageURL={wallpaper.thumbnail}
            categories={wallpaper.categories}
            onClick={clickHandler}
            />
      )}
      
    </div>
  )
}
