import React from 'react'
import css from './DarkerBackground.module.css'
import { motion } from 'framer-motion'

export default function DarkerBackground({className = '', ...props}) {
  
  return (
    <motion.div 
      {...props}
      className={className + ' ' + css.background} 
      initial={{
        opacity: 0
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0
      }}
      >
    </motion.div>
  )
}
