import React from 'react'
import BackgroundLoader from '../../UI/BackgroundLoader/BackgroundLoader'
import Loader from '../../UI/Loader/Loader';
import { useImgLoading } from '../../../hooks/useImgLoading';
import css from './WallpaperView.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(css);

export default function ImageWallpaper({imageURL, preloadImageURL, elementRef}) {
    const fullWallpaper = imageURL;
    const [isImgLoading] = useImgLoading(fullWallpaper);
    
    const actualImage = isImgLoading ? preloadImageURL : fullWallpaper;
    
  return (
    <div>
        {/* Loader */}
        { (isImgLoading) && <div className='center' style={{zIndex: 10, filter: 'drop-shadow(0 0 3px #0003)'}}><Loader/></div>}

        { !isImgLoading || preloadImageURL
        ?
            <img 
                ref={elementRef}
                className={cx('wallpaper', {
                    imageLoading: isImgLoading
                })} 
                src={ actualImage } 
                alt='Wallpaper' 
                
                />
        :
            <BackgroundLoader isVisible={true} style={{aspectRatio: 0.5, height: '75svh', position: 'initial'}}/>
        }
    </div>
  )
}
