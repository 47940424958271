import React, { useState, useMemo, useCallback } from 'react';
import WallpaperService from '../API/WallpaperService.js';
import WallpaperList from './WallpaperList.jsx';
import Loader from './UI/Loader/Loader.jsx';
import { useFetching } from '../hooks/useFetching.js';
import { useInView } from 'react-intersection-observer';
import LiveWallpaperService from '../API/LiveWallpaperService.js';
import { motion } from 'framer-motion';

const PAGE_LIMIT = 28;

function WallpaperFetchList({ fetchBy, value, filter, onItemClick, onPremiumItemClick }) {
  const [pagesCount, setPagesCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [loadedPages, setLoadedPages] = useState(0);

  const [wallpapers, setWallpapers] = useState([]);
  const fetchCallback = useCallback((response) => {
    const responsePagesCount = Number(response.data.info.last_page);
    setPagesCount(responsePagesCount);
    
    let data = response.data.data;

    if (filter) {
      data = data.filter(filter);
    }

    setWallpapers(s => s.concat(data));

    setLoadedPages(s => s + 1);
  }, [filter]);

  const [isLoading, isError] = useFetching(
    useMemo(() => {
      const fetchTypes = {
        all: () => WallpaperService.get(selectedPage, PAGE_LIMIT),
        category: (categoryId) => WallpaperService.byCategory(categoryId).get(selectedPage, PAGE_LIMIT),
        new: () => WallpaperService.sortBy('DT_createdAt').get(selectedPage, PAGE_LIMIT),
        live: () => LiveWallpaperService.get(selectedPage, PAGE_LIMIT),
      }

      try {
        return fetchTypes[fetchBy](value);
      } catch (e) {
        console.error(`fetchType: "${fetchBy}" not found`, e);
      }
      
    }, [selectedPage, fetchBy, value]),
    fetchCallback
  );


  function onScrollEnd(inView) {
    if (! inView) return;
    
    // Exit if offline
    if ( !navigator.onLine ) {
      alert("No internet connection...");
      return;
    }
    const finalPage = (selectedPage >= pagesCount);

    if ( isLoading || finalPage ) return;
    
    setSelectedPage(loadedPages + 1);
  }
  
  const [observeRef] = useInView({onChange: onScrollEnd});

  const categoryClasses = {
    '12': 'matching',
    '23': 'trio',
  }

  return (<>
    {wallpapers.length || isLoading
    ?
      <WallpaperList 
        list={wallpapers} 
        navigateTo={fetchBy === 'live' ? '/live-wallpaper/' : '/wallpaper/'}
        onItemClick={onItemClick} 
        onPremiumItemClick={onPremiumItemClick}
        className={fetchBy === 'category' && categoryClasses[value?.toString()]}
        
        />
    :
      <motion.h2 
        style={{textAlign: 'center'}}
        transition={{duration: 1}}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        >
        There's no wallpapers with this category
      </motion.h2>
    }
    {(selectedPage < pagesCount && !isError) &&
      <div ref={observeRef} className="mh-[1em]">
        <Loader/>
      </div>
    }
  </>);
}

export default WallpaperFetchList;
