import React, { useEffect, useState } from 'react'
import css from './BackgroundLoader.module.css'



export default function BackgroundLoader({isVisible = true, transition = 300, ...props}) {
    const [show, setShow] = useState(true);

    useEffect(() => {
        if ( isVisible ) return;
        const timer = setTimeout(() => {
            setShow(s => !s);
        }, transition);

        return () => clearTimeout(timer);
    }, [isVisible, transition])
  return (
    <>
        {show && <div 
            className={css.background} 
            style={{
                animationDelay: Math.random()/2 + 's',
                transition: transition + 'ms'
                }}
            data-loading={isVisible}
            {...props}
            ></div>}
    </>
  )
}
