export function getScrollWidth() {
    return window.innerWidth - document.querySelector(':root').offsetWidth;
}

export function onResize(){
    const root = document.querySelector(':root');
    if( root.matches(':has(.blockScroll)') ) return;

    root.setAttribute('style', '--compensate-scrollbar: ' + getScrollWidth() + 'px');    
}

setInterval(onResize, 1000);